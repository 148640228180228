<template>
  <div>
    <b-navbar toggleable="sm" type="light" variant="light" class="top-nav">
      <b-navbar-brand target="_blank" href="http://www.thinkerx.com/"> 新格尔软件
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item target="_blank" href="http://www.menccc.com/#">门窗CC</b-nav-item>
          <b-nav-item target="_blank" href="https://www.eggrj.com/">柜柜</b-nav-item>
          <b-nav-item target="_blank" href="http://kevke.com/kshow/index.php?r=main">K秀导航</b-nav-item>
          <b-nav-item target="_blank" href="http://www.mentuwang.com">门图网</b-nav-item>
          <b-nav-item target="_blank" href="https://windowcc.com/home">画门窗</b-nav-item>
        </b-navbar-nav>

        <b-nav-text class="ml-auto">测量大师全国统一服务热线：<span class="dark1">0510-85186908</span></b-nav-text>
      </b-collapse>
    </b-navbar>
    <b-container fluid class="part1">
      <div class="banner_video">
        <video src="https://celiang.oss-cn-hangzhou.aliyuncs.com/video_tutorial/measure_index.mp4" autoplay="" muted=""
          loop="" class="bac_video"></video>
      </div>
      <b-row>
        <!-- <b-col class="col-12 text1 text-center text-sm-left">还原真实测量现场</b-col> -->
        <b-col class="col-12 text2 text-center text-sm-left">让测量不再出错</b-col>
        <b-col class="col-12 text3 text-center text-sm-left">
          测量大师适用于门窗、全屋定制行业的门店使用，以提升经销商核心竞争力为主要思想，主要针对于行业头部玩家，以智能量尺、还原现场为出发点，从售前、售中、售后三大环节为门店提供营销获客、测量设计、入户安装、售后维保等一系列产品解决方案。
        </b-col>
        <b-col class="col-12 text3 text-center text-sm-left">
          <b-button pill class="free mr-4" @click="toTry" variant="primary">免费试用</b-button>
          <b-button pill class="free" @click="isShowDown = true" variant="outline-light">立即下载</b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="list1">
      <b-row>
        <b-col order="1" class="col-sm-3 col-6 text-center">
          <div class="num">{{ num1 }}</div>
          <div class="des">使用用户</div>
        </b-col>
        <b-col order="2" class="col-sm-3 col-6 text-center">
          <div class="num">{{ num2 }}</div>
          <div class="des">创建项目</div>
        </b-col>
        <b-col order="4" order-sm="3" class="col-sm-3 col-6 text-center">
          <div class="num">{{ num3 }}</div>
          <div class="des">还原现场</div>
        </b-col>
        <b-col order="3" order-sm="4" class="col-sm-3 col-6 text-center">
          <div class="num">{{ num4 }}</div>
          <div class="des">分布城市</div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="part2">
      <div class="part2_title text-center">
        助力经销商获客、签单、提升服务体验
      </div>
      <b-row class="text-center justify-content-around">
        <b-col cols="6" sm="2" class="mb-4 ">
          <b-img class="nohover" :src="require('../../assets/img/index/icon1.png')"></b-img>
          <b-img class="hover" :src="require('../../assets/img/index/icon1_w.png')"></b-img>
          <div class="col-title">获客谈单</div>
          <div class="des text-left">
            通过测量大师提供的获客功能触达目标客户群体，进行服务的曝光以此产生意向，结合传统销售技巧达到谈单签约的目的。
          </div>
          <div class="btn" @click="toTry">免费试用</div>
        </b-col>
        <b-col cols="6" sm="2" class="mb-4">
          <b-img class="nohover" :src="require('../../assets/img/index/icon2.png')"></b-img>
          <b-img class="hover" :src="require('../../assets/img/index/icon2_w.png')"></b-img>
          <div class="col-title">测量、非标准定制</div>
          <div class="des">
            提供门窗、柜体、阳光房、别墅等测量方案，对测量目标场景化，标准化、不漏测、不错测、保证测量不出错，沟通不重复。
          </div>
          <div class="btn" @click="toTry">免费试用</div>
        </b-col>
        <b-col cols="6" sm="2" class="mb-4">
          <b-img class="nohover" :src="require('../../assets/img/index/icon3.png')"></b-img>
          <b-img class="hover" :src="require('../../assets/img/index/icon3_w.png')"></b-img>
          <div class="col-title">下单、设计、算料生产</div>
          <div class="des">
            测量大师与CC无缝对接，对测量单实现自动化设计、拆单、算料，解决出货效率低下，沟通不彻底所导致生产出错的一系列问题。
          </div>
          <div class="btn" @click="toTry">免费试用</div>
        </b-col>
        <b-col cols="6" sm="2" class="mb-4">
          <b-img class="nohover" :src="require('../../assets/img/index/icon4.png')"></b-img>
          <b-img class="hover" :src="require('../../assets/img/index/icon4_w.png')"></b-img>
          <div class="col-title">运输、搬运、安装</div>
          <div class="des">
            从成品出库，到物流运输到搬运入户到安装服务，实现标准化、流程化、数据化管理，达到人不等货，时间不催人，安装不停留的目的。
          </div>
          <div class="btn" @click="toTry">免费试用</div>
        </b-col>
        <b-col cols="6" sm="2" class="mb-4">
          <b-img class="nohover" :src="require('../../assets/img/index/icon5.png')"></b-img>
          <b-img class="hover" :src="require('../../assets/img/index/icon5_w.png')"></b-img>
          <div class="col-title">售后维保</div>
          <div class="des">
            以安装服务为导向，多维度提升门店对终端客户的服务体验，数据驱动业务，延伸多个服务链，让服务溢价，产品更具区域影响力。
          </div>
          <div class="btn" @click="toTry">免费试用</div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="part3">
      <div class="part3_title text-center">4大解决方案</div>
      <div class="part3_futitle text-center">
        测量大师依托新格尔在行业内沉淀的11年经验，结合已有的业务场景，帮助经销商增长业务
      </div>
      <div class="content">
        <div class="left">
          <div class="item" @click="cur = 0" :class="{ active: cur == 0 }">
            门窗
          </div>
          <div class="item" @click="showTips">全屋</div>
          <div class="item" @click="showTips">别墅</div>
          <div class="item" @click="showTips">阳光房</div>
          <!-- <div class="item"
               @click="cur=1"
               :class="{active:cur==1}">全屋</div>
          <div class="item"
               @click="cur=2"
               :class="{active:cur==2}">别墅</div>
          <div class="item"
               @click="cur=3"
               :class="{active:cur==3}">阳光房</div> -->
        </div>
        <div class="right">
          <div class="info info1" v-show="cur == 0">
            <b-img class="content_img" :src="require('../../assets/img/index/men1.jpg')"></b-img>
            <div class="content_title">门窗行业产品解决方案</div>
            <div class="content_futitle">
              根据经销商品牌、所处地段以及经销商所针对的终端客户群体，测量大师一一进行了标签化、分组，用户画像的搭建，根据自动化模型为其提供合适的产品解决方案，如只需要老板一人使用的个人版，需要销售、设计师、安装测量人员使用的团队版本、需要经销商与工厂、材料商对接的高级版等。同时测量大师还满足与第三方ERP、SaaS系统、电商对接，多方位帮扶经销商将服务做的更专业更有深度。
            </div>
            <div class="d-flex justify-content-around justify-content-sm-start">
              <b-button pill @click="toSolution" class="mr-3" variant="outline-primary">了解详情</b-button>
              <b-button pill class="" @click="toTry" variant="primary">免费试用</b-button>
            </div>
          </div>
          <!-- <div class="info info_other"
               v-show="cur==1">
            <div class="info_other_content">
              <img src="../../assets/img/index/kong1.png"
                   alt="">
              <div class="text">即将上线</div>
            </div>
          </div>
          <div class="info info_other"
               v-show="cur==2">
            <div class="info_other_content">
              <img src="../../assets/img/index/kong1.png"
                   alt="">
              <div class="text">即将上线</div>
            </div>
          </div>
          <div class="info info_other"
               v-show="cur==3">
            <div class="info_other_content">
              <img src="../../assets/img/index/kong1.png"
                   alt="">
              <div class="text">即将上线</div>
            </div>
          </div> -->
        </div>
      </div>
    </b-container>
    <!-- 暂时隐藏 -->
    <fluidb-container v-show="false" class="part4">
      <div class="part4_title text-center">客户成功故事</div>
      <div class="part4_futitle text-center">
        用使命促进行业改革，测量赋能经销商服务升级
      </div>
      <div class="lunbo_fa">
        <swiper :options="swiperOption" class="lunbo" ref="mySwiper">
          <!-- slides -->
          <swiper-slide v-for="(item, i) in list" :key="i">
            <!-- <b-card :img-src="item.surface_img"
                    img-alt="Image"
                    img-top
                    :title="item.name"
                    @click="todetail"
                    tag="article">
              <b-card-text>
                {{item.subtitle}}
              </b-card-text>
            </b-card> -->
            <div class="list" @click="todetail_case(item.id)">
              <div class="list_img">
                <div class="child">
                  <img :src="item.surface_img" alt="" />
                </div>
              </div>
              <div class="list_info">
                <div class="title">{{ item.name }}</div>
                <div class="text">{{ item.subtitle }}</div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="more" @click="toCase">
              <div class="more_img">
                <div class="child">
                  <div class="content">查看更多案例</div>
                </div>
              </div>
              <div class="more_text"></div>
            </div>
          </swiper-slide>
          <!-- Optional controls -->
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </fluidb-container>
    <b-container fluid class="part5">
      <div class="part5_title text-center">产品动态</div>
      <div class="part5_futitle text-center">产品迭代更新</div>
      <div class="part5_content" v-if="list_news.length > 0">
        <ul class="list-unstyled">
          <b-media tag="li" v-if="list_news[0]" @click="todetail_news(list_news[0].id)">
            <template v-slot:aside>
              <b-img :src="list_news[0].surface_img"></b-img>
            </template>
            <p class="title mt-0 mb-2 my-2">{{ list_news[0].title }}</p>
            <p class="futitle mb-0">发表于 {{ list_news[0].created_at }}</p>
          </b-media>
          <div class="other">
            <b-media tag="li" v-for="(item, index) in list_news.slice(1, 4)" :key="index"
              @click="todetail_news(item.id)">
              <template v-slot:aside>
                <b-img :src="item.surface_img"></b-img>
              </template>
              <p class="title mt-0 mb-2">{{ item.title }}</p>
              <p class="futitle mb-0">发表于 {{ item.created_at }}</p>
            </b-media>
          </div>
        </ul>
        <div class="text-center">
          <b-button pill @click="toNews" variant="outline-primary">全部新闻</b-button>
        </div>
      </div>
      <div class="part5_kong" v-else>
        <div class="kong">
          <img src="../../assets/img/index/kong2.png" alt="" />
          <div class="kong_text">管理员还未发布新闻</div>
        </div>
      </div>
    </b-container>
    <b-container fluid class="part6">
      <div class="part6_title text-center">我们的客户</div>
      <div class="part6_futitle text-center">
        测量大师已服务<span class="text-primary">“3000+”</span>门店
      </div>
      <div class="brand">
        <b-img :src="require('../../assets/img/logo/logo (1).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (2).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (3).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (4).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (5).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (6).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (7).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (8).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (9).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (10).png')"></b-img>
      </div>
    </b-container>
    <transition name="slide-fade">
      <div class="dialog" v-show="isShow">
        <div class="content">
          <b-img class="img" :src="require('../../assets/img/index/kong5.png')"></b-img>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="dialog" v-show="isShowDown">
        <div class="content_down">
          <div class="item">
            <img src="../../assets/img/production/QRCode.png" alt="" />
            <div class="tip"><i class="iconfont icon-anzhuo"></i>安卓下载</div>
          </div>
          <div class="item">
            <img src="../../assets/img/production/ios.png" alt="" />
            <div class="tip"><i class="iconfont icon-pingguo"></i>IOS下载</div>
          </div>
          <div class="close_down" @click="isShowDown = false">
            <b-icon icon="x-circle"></b-icon>
          </div>
        </div>
      </div>
    </transition>
    <myhead :isIndex="true" :background="'transparent'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
  import myhead from "@/components/head.vue";
  import foot from "@/components/foot.vue";
  import right from "@/components/right.vue";
  export default {
    name: "index",
    data() {
      return {
        slide: 0,
        sliding: null,
        variant: "light",
        type: "light",
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num: {},
        timer: null,
        swiperOption: {
          // some swiper options/callbacks
          // 所有的参数同 swiper 官方 api 参数
          // ...
          prevButton: ".swiper-button-prev",
          nextButton: ".swiper-button-next",
          slidesPerView: 4,
          spaceBetween: 20
          // autoplay: 2000,
          // speed: 300,
        },
        list: [],
        list_news: [],
        screenWidth: document.body.clientWidth, // 屏幕尺寸
        cur: 0,
        isShow: false,
        isShowDown: false
      };
    },
    components: {
      foot,
      right,
      myhead
    },
    mounted() {
      this.onResize();
      // console.log(this.$refs.mySwiper)

      window.addEventListener("resize", this.onResize);
      this.offical();
      this.listCase();
      this.listNews();
    },

    // 不知道为何，切换路由，滚动监听事件没有销毁，得刷新或者主动移除才行
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
    methods: {
      toTry() {
        this.$router.push({
          name: "Try"
        });
      },
      toCase() {
        this.$router.push({
          name: "Case"
        });
      },
      toNews() {
        this.$router.push({
          name: "News"
        });
      },
      toIndex() {
        this.$router.push({
          name: "Index"
        });
      },
      toSolution() {
        this.$router.push({
          name: "Solution"
        });
      },
      todetail_case(id) {
        this.$router.push({
          name: "CaseDetail",
          query: {
            id: id
          }
        });
      },
      todetail_news(id) {
        this.$router.push({
          name: "NewsDetail",
          query: {
            id: id
          }
        });
      },
      showTips() {
        this.isShow = true;
        setTimeout(() => {
          this.isShow = false;
        }, 1000);
      },
      onResize() {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth < 576) {
          this.swiperOption.slidesPerView = 1;
        } else {
          this.swiperOption.slidesPerView = 4;
        }
      },
      onSlideStart() {
        this.sliding = true;
      },
      onSlideEnd() {
        this.sliding = false;
      },
      console(num) {
        console.log(num);
      },
      // 案例列表
      listCase() {
        let params = {
          location: 1
        };
        this.$axios
          .get(`${this.$Tools.Conts.domain}official/successful-cases`, {
            params
          })
          .then(res => {
            if (res.data.code == 0) {
              this.list = res.data.data;
            }
          });
      },
      // 新闻列表
      listNews() {
        let params = {
          with_paginate: 1,
          page: 0
        };
        this.$axios
          .get(`${this.$Tools.Conts.domain}official/news`, {
            params
          })
          .then(res => {
            if (res.data.code == 0) {
              this.list_news = res.data.data.data;
            }
          });
      },
      offical() {
        this.$axios.get(`${this.$Tools.Conts.domain}official/index`).then(res => {
          if (res.data.code == 0) {
            this.num = res.data.data;
            // console.log(res.data.data);
            this.beatNums();
          }
        });
      },
      //数字滚动效果
      beatNums() {
        let num1 = this.num.account_count,
          num2 = this.num.project_count,
          num3 = this.num.cave_count,
          num4 = this.num.city_count;
        this.timer = setInterval(() => {
          let step1 = Math.ceil(num1 / (2000 / 15));
          let step2 = Math.ceil(num2 / (2000 / 15));
          let step3 = Math.ceil(num3 / (2000 / 15));
          let step4 = Math.ceil(num4 / (2000 / 15));
          if (this.num1 >= num1) {
            this.num1 = num1;
          } else {
            this.num1 += step1;
          }
          if (this.num2 >= num2) {
            this.num2 = num2;
          } else {
            this.num2 += step2;
          }
          if (this.num3 >= num3) {
            this.num3 = num3;
          } else {
            this.num3 += step3;
          }
          if (this.num4 >= num4) {
            this.num4 = num4;
          } else {
            this.num4 += step4;
          }
          if (
            this.num1 >= num1 &&
            this.num2 >= num2 &&
            this.num3 >= num3 &&
            this.num4 >= num4
          ) {
            clearInterval(this.timer);
          }
        }, 15);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top-nav {
    display: none;
    font-size: 12px;

    .dark1 {
      color: black;
    }

    &.navbar-light {
      .navbar-brand {
        color: black;
      }
    }

    .navbar-brand {
      font-size: 12px;
    }

    .navbar-toggler {
      font-size: 1rem;
    }

    .nav-item {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 30%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  .all {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .top_list {
    .list-group-item+.list-group-item {
      border: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .list1 {
    .col {
      padding: 1.5rem 1.25rem;
      border: 1px solid rgba(0, 0, 0, 0.125);
      background: rgb(41, 41, 41);
      color: white;

      &:nth-child(even) {
        background: black;
      }

      .num {
        font-size: 24px;
        margin-bottom: 0.75rem;
      }

      .des {
        font-size: 16px;
        color: gray;
      }
    }
  }

  .flex-direction {
    flex-direction: inherit;
  }

  .part1 {
    overflow: hidden;
    padding-top: 5rem;
    padding-bottom: 3rem;
    // background: rgb(68, 70, 72);
    background: url("../../assets/img/index/bac_index.png") no-repeat;
    background-size: cover;
    color: white;
    position: relative;

    .banner_video {
      display: none;
      overflow: hidden;
      width: 100%;
      // height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background: #000;
    }

    .text1 {
      font-size: 1rem;
      opacity: 1;
    }

    .text2 {
      font-size: 1.8rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    .text3 {
      font-size: 0.8rem;
    }

    .free {
      font-size: 0.7rem;
      padding: 0.5rem 1.5rem;
      margin-top: 1rem;
    }
  }

  .part2 {
    padding: 3.44rem 15px 0;
    background: url("../../assets/img/index/P1.png");

    img {
      width: 2rem;
      margin-bottom: 0.63rem;
    }

    .part2_title {
      padding: 0 0 2.47rem;
      font-size: 1.2rem;
    }

    .col-title {
      font-size: 0.9rem;
      padding-bottom: 15px;
    }

    .des {
      font-size: 0.5rem;
      color: gray;
    }

    .btn {
      padding: 0.5rem 2.5rem;
      background: white;
      color: black;
      border-radius: 3rem;
      font-size: 0.9rem;
      margin-top: 1rem;
      display: none;
    }

    .row {
      margin-left: 0;
      margin-right: 0;

      &>div {
        padding: 1rem 0;

        .hover {
          display: none;
        }
      }

      &>div:hover {
        background: #2b85e4;
        color: white;
        border-radius: 0.3rem;

        .nohover {
          display: none;
        }

        .hover {
          display: inline-block;
        }

        .des {
          display: none;
        }

        .btn {
          display: inline-block;
        }
      }
    }
  }

  .part3 {
    padding: 0 15px 5rem;

    .part3_title {
      padding: 2rem 0 0.5rem;
      font-size: 1.2rem;
    }

    .part3_futitle {
      font-size: 0.5rem;
      padding-bottom: 1.56rem;
      color: #777777;
    }

    .content {
      display: block;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .left {
        text-align: center;
        border-bottom: 1px solid #e5e5e5;
        font-size: 0.63rem;
        color: #777777;
        padding: 1rem 0;
        display: flex;
        justify-content: space-around;

        .item {
          cursor: pointer;

          &:hover {
            color: #2b85e4;
          }

          &.active {
            color: #2b85e4;
          }
        }
      }

      .right {
        width: 100%;
        padding: 2rem 1rem;

        .info_other {
          width: 100%;
          height: 100%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50%;
          }

          .text {
            padding-top: 1rem;
            font-size: 1.1rem;
            color: #777777;
          }
        }

        .content_img {
          width: 100%;
        }

        .content_title {
          font-size: 1rem;
          padding: 1rem 0;
        }

        .content_futitle {
          font-size: 0.5rem;
          text-indent: 2rem;
          padding-bottom: 2rem;
          color: #777777;
        }

        .btn {
          font-size: 0.7rem;
        }
      }
    }
  }

  .part4 {
    padding: 3rem 0;
    background: url("../../assets/img/index/P2.png");

    .part4_title {
      padding: 0 0 0.5rem;
      font-size: 1.2rem;
    }

    .part4_futitle {
      font-size: 0.5rem;
      padding-bottom: 1rem;
      color: gray;
    }

    .lunbo_fa {
      padding: 1rem 3rem;
      position: relative;

      .lunbo {
        /deep/ .swiper-slide {
          width: 200px;

          .card {
            cursor: pointer;

            img {
              height: 10rem;
            }

            .card-title {
              font-size: 1rem;
            }

            .card-text {
              font-size: 0.7rem;
              color: #999999;
            }
          }

          .list {
            cursor: pointer;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0.25rem;
            overflow: hidden;

            .list_img {

              // .child {
              //   height: 0;
              //   padding-bottom: 50%;
              //   position: relative;
              //   img {
              //     position: absolute;
              //     top: 0;
              //     bottom: 0;
              //     left: 0;
              //     right: 0;
              //     width: 100%;
              //     height: 100%;
              //   }
              // }
              .child {
                img {
                  width: 100%;
                }
              }
            }

            .list_info {
              padding: 1rem;

              .title {
                font-size: 1rem;
                margin-bottom: 0.5rem;
              }

              .text {
                font-size: 0.8rem;
                color: #999999;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .more {
            width: 100%;
            cursor: pointer;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0.25rem;

            .more_img {
              .child {
                height: 0;
                padding-bottom: 50%;
                position: relative;

                .content {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background: #007bff;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .more_text {
              height: 6rem;
            }
          }
        }
      }
    }
  }

  .part5 {
    padding: 3rem 0;

    .part5_title {
      padding: 0 0 0.5rem;
      font-size: 1.2rem;
    }

    .part5_futitle {
      font-size: 0.5rem;
      padding-bottom: 2rem;
      color: #777777;
    }

    .title {
      font-size: 1rem;
      // width: 16rem;
    }

    .futitle {
      font-size: 0.7rem;
      color: gray;
    }

    .part5_kong {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        width: 80%;
      }

      .kong_text {
        padding-top: 1rem;
        font-size: 1.1rem;
        color: #777777;
      }
    }

    .list-unstyled {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.2rem;

      .media {
        border-bottom: 1px solid #c4c4c4;
        padding: 1rem 0;
        width: 90%;
        margin: 0 auto;
        cursor: pointer;
      }

      &>.media {
        display: block;

        .media-aside {
          img {
            width: 100%;
            display: block;
          }
        }

        .title {
          width: auto;
          font-size: 1.1rem;
          color: #777777;
        }
      }

      .other {
        width: 100%;

        .media {
          .media-aside {
            img {
              width: 7rem;
              height: auto;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .btn {
      font-size: 0.7rem;
      padding: 0.5rem 2rem;
    }
  }

  .part6 {
    padding: 3rem 0;
    background: url("../../assets/img/index/P3.png");

    .part6_title {
      padding: 0 0 0.5rem;
      font-size: 1.2rem;
    }

    .part6_futitle {
      font-size: 0.5rem;
      padding-bottom: 3rem;
      color: #777777;
    }

    .brand {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 850px;
      margin: auto;

      img {
        width: 160px;
        border: 1px solid #c1c1c1;
        margin-bottom: 0.5rem;
      }
    }
  }

  .dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.3);

    .content {
      position: relative;
      width: 16rem;
      margin: 50vh auto 0;
      transform: translateY(-50%);

      .img {
        width: 16rem;
        display: block;
      }
    }

    .content_down {
      position: relative;
      width: 90%;
      padding: 4rem 2rem;
      margin: 50vh auto 0;
      transform: translateY(-50%);
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 6px;

      .item {
        text-align: center;

        img {
          width: 7rem;
        }

        .tip {
          padding-top: 0.8rem;

          i {
            margin-right: 0.3rem;
          }
        }
      }

      .close_down {
        position: absolute;
        bottom: -6rem;
        font-size: 2rem;
        color: white;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.8s;
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active for below version 2.1.8 */
    {
    opacity: 0;
  }

  @media screen and (min-width: 576px) {
    .top-nav {
      display: flex;
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .part1 {
      padding-top: 10rem;
      padding-bottom: 11rem;
      padding-left: 10rem;
      padding-right: 10rem;
      // background: rgb(68, 70, 72);
      // background: url("../../assets/img/index/bac_index.png") no-repeat;
      // background-size: cover;
      background: none;
      color: white;

      .banner_video {
        display: block;
      }

      .row {
        width: 40%;
      }

      .text1 {
        font-size: 1.5rem;
      }

      .text2 {
        font-size: 3rem;
        padding-top: 2rem;
        padding-bottom: 0.3rem;
      }

      .text3 {
        font-size: 0.8rem;
        opacity: 0.9;
      }

      .free {
        font-size: 1rem;
        padding: 0.8rem 2.8rem;
        margin-top: 1rem;
      }
    }

    .part2 {
      padding: 5rem 10rem;

      img {
        width: 3.5rem;
        margin-bottom: 2.56rem;
      }

      .part2_title {
        padding: 0 0 5rem;
        font-size: 2rem;
      }

      .col-title {
        font-size: 1rem;
        padding-bottom: 15px;
      }

      .des {
        font-size: 0.8rem;
        color: gray;
      }

      .btn {
        padding: 0.5rem 2.5rem;
        background: white;
        color: black;
        border-radius: 3rem;
        font-size: 0.9rem;
        margin-top: 1rem;
        display: none;
      }
    }

    .part3 {
      padding: 0 6rem 5rem;
      width: 100%;

      .info2,
      .info3,
      .info4 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .part3_title {
        padding: 5rem 0 0.5rem;
        font-size: 2rem;
      }

      .part3_futitle {
        font-size: 1rem;
        padding-bottom: 4rem;
        color: #777777;
      }

      .content {
        max-width: 1200px;
        margin: auto;
        display: flex;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .left {
          display: block;
          flex-shrink: 0;
          width: 13rem;
          text-align: center;
          border-right: 1px solid #e5e5e5;
          font-size: 1.1rem;
          color: #777777;
          padding: 4rem 0;

          .item {
            margin-bottom: 4rem;
            cursor: pointer;

            &:hover {
              color: #2b85e4;
            }

            &.active {
              color: #2b85e4;
            }
          }
        }

        .right {
          padding: 4rem 4.63rem;

          .info_other {
            width: 100%;
            height: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 70%;
            }

            .text {
              padding-top: 1rem;
              font-size: 1.2rem;
              color: #777777;
            }
          }

          .content_img {
            width: 100%;
          }

          .content_title {
            font-size: 1.3rem;
            padding: 1rem 0;
          }

          .content_futitle {
            font-size: 0.8rem;
            text-indent: 2rem;
            padding-bottom: 2rem;
          }

          .btn {
            font-size: 0.9rem;
            padding: 0.6rem 2.2rem;
          }
        }
      }
    }

    .part4 {
      padding: 5rem 0;
      background: url("../../assets/img/index/P2.png");

      .part4_title {
        padding: 0 0 0.5rem;
        font-size: 2rem;
      }

      .part4_futitle {
        font-size: 1rem;
        padding-bottom: 4rem;
        color: gray;
      }

      .lunbo_fa {
        position: relative;
        padding: 0rem 10rem;

        /deep/ .swiper-button-prev {
          left: 5rem;
        }

        /deep/ .swiper-button-next {
          right: 5rem;
        }

        .lunbo {
          /deep/ .swiper-slide {
            width: 200px;

            .card {
              cursor: pointer;

              .card-title {
                font-size: 1.1rem;
              }

              .card-text {
                font-size: 0.9rem;
                color: #999999;
              }
            }

            .list {
              cursor: pointer;
              background: white;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              border-radius: 0.25rem;
              overflow: hidden;

              .list_img {

                // .child {
                //   height: 0;
                //   padding-bottom: 50%;
                //   position: relative;
                //   img {
                //     position: absolute;
                //     top: 0;
                //     bottom: 0;
                //     left: 0;
                //     right: 0;
                //     width: 100%;
                //     height: 100%;
                //   }
                // }
                .child {
                  img {
                    width: 100%;
                  }
                }
              }

              .list_info {
                padding: 1.25rem;

                .title {
                  font-size: 1rem;
                  margin-bottom: 0.75rem;
                }

                .text {
                  font-size: 0.8rem;
                  color: #999999;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            .more {
              cursor: pointer;
              background: white;
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              border-radius: 0.25rem;

              .more_img {
                .child {
                  height: 0;
                  padding-bottom: 50%;
                  position: relative;

                  .content {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: #007bff;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }

              .more_text {
                height: 6rem;
              }
            }
          }
        }
      }
    }

    .part5 {
      padding: 5rem 0;

      .part5_title {
        padding: 0 0 0.5rem;
        font-size: 2rem;
      }

      .part5_futitle {
        font-size: 1rem;
        padding-bottom: 4rem;
        color: gray;
      }

      .title {
        font-size: 1.2rem;
        width: 16rem;
      }

      .futitle {
        font-size: 0.8rem;
        color: gray;
      }

      .part5_kong {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
          width: 50%;
        }

        .kong_text {
          padding-top: 1rem;
          font-size: 1.1rem;
          color: #777777;
        }
      }

      .list-unstyled {
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;

        .media {
          border-bottom: 1px solid #c4c4c4;
          padding: 1rem 0;
          margin: 0 0 0.2rem 0;
          width: auto;
          cursor: pointer;
        }

        &>.media {
          flex-flow: column;
          border-bottom: none;

          .media-aside {
            img {
              width: 20rem;
              height: auto;
            }
          }

          .title {
            width: 16rem;
            font-size: 1.2rem;
          }
        }

        .other {
          width: auto;

          .media {
            .media-aside {
              img {
                width: 7rem;
                height: auto;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .btn {
        font-size: 1rem;
        padding: 0.5rem 2rem;
        margin-top: 1rem;
      }
    }

    .part6 {
      padding: 5rem 0;
      background: url("../../assets/img/index/P3.png");

      .part6_title {
        padding: 0 0 0.5rem;
        font-size: 2rem;
      }

      .part6_futitle {
        font-size: 1rem;
        padding-bottom: 3rem;
        color: #777777;
      }

      .brand {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 850px;
        margin: auto;

        img {
          width: 160px;
          border: 1px solid #c1c1c1;
          margin-bottom: 0.5rem;
        }
      }
    }

    .dialog {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1050;
      width: 100%;
      height: 100%;
      overflow: hidden;
      outline: 0;
      background: rgba(0, 0, 0, 0.3);

      .content {
        position: relative;
        width: 16rem;
        margin: 50vh auto 0;
        transform: translateY(-50%);

        .img {
          width: 16rem;
          display: block;
        }
      }

      .content_down {
        position: relative;
        width: 30rem;
        padding: 4rem 2rem;
        margin: 50vh auto 0;
        transform: translateY(-50%);
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 6px;

        .item {
          text-align: center;

          img {
            width: 8rem;
          }

          .tip {
            padding-top: 0.8rem;

            i {
              margin-right: 0.3rem;
            }
          }
        }

        .close_down {
          position: absolute;
          bottom: -6rem;
          font-size: 2rem;
          color: white;
          cursor: pointer;
        }
      }
    }
  }

  // @media screen and (min-width: 1700px) {
  //   .part1 {
  //     padding-top: 17.5rem;
  //     padding-bottom: 17.5rem;
  //   }
  // }
  @media screen and (min-width: 1600px) {
    .part1 {
      padding-bottom: 13rem;
      background: none;

      .banner_video {
        display: block;

        video {
          width: 100%;
        }
      }
    }
  }
</style>